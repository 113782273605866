<template>
<div>
  
                        

          <div class="rounded-lg shadow border border-white bg-white">

            <div class="row justify-content-centre align-items-center">    
            
            <div class="col">
            <h4 class="m-3 font-weight-lighter var_color float-left"> <span class="fa fa-bars"></span> Articles    </h4> 

                <b-button pill size="sm"  class="mt-3 mb-3 float-left" variant="outline-success" @click="add_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Ajouter</span> 
                </b-button>

                <b-button pill size="sm"  class="ml-3 mt-3 mb-3 float-left" variant="outline-primary" @click="exportDox">
                            <i class="fa fa-print"></i>
                    <span> Impr</span> 
                </b-button>
            </div>
            



            <div class="col">
                <div class="input-group col">
                                    <select class="form-control border border-ligth " id="selected_centre" v-model="selectedCategorie">
                                            <option v-for="item in categories" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>
                                            <option value=0>Tous</option>

                                     </select>


                                    <div class="input-group-prepend">
                                        <button @click="click_ArticlList" class="btn  btn-light  border border-ligth rounded-right" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                </div>
            </div>

             <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher" class="col-3 mr-0"></b-form-input>
 
                                    <b-button pill size="sm"  class="col-1 mt-0 mr-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

            </div>
       



        <b-table 
        id="main-table"
        responsive  
        sort-icon-left
        head-variant="light"
        :filter="filter"
        :fields="fields" 
        :items="itemslist"
        :per-page="perPage"
        :current-page="currentPage"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>

                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-success" @click="stock_click(row.item, $event.target)">
                                <i class="fa fa-cubes"></i> 
                    </b-button>
                </template>
        
        </b-table>

                        <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>


    <!-- le modal -->
    <b-modal id="banqModal" :title="itemForm.ref" size="lg" hide-footer>
           <form @submit.prevent="handleSubmit">
            <div class="row">
            <div class="form-group col">
                <label for="ref">Référence</label>
                <input type="text" v-model="itemForm.ref" id="ref" name="ref" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.ref.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col">

                            <label for="itemForm">Catégorie</label>
                            <select class="form-control" id="itemForm" v-model="itemForm.categorie_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.categorie_id.$error }">
                            <option v-for="item in categories" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

            </div>
            </div>

            <div class="row">
                

            <div class="form-group col">
                <label for="code_bar">Code Barre</label>

                <b-button @click="addBarreCode_click"  size="sm"  class="ml-1 mr-1 float-left p-1 text-decoration-none" variant="link">
                                    <i class="fa fa-sync"></i>                
                </b-button> 


                <input type="text" v-model="itemForm.code_bar" id="code_bar" name="code_bar" class="form-control"/>
            </div>


            <div class="form-group col">
                <label for="unite">Unité</label>
                <input type="text" v-model="itemForm.unite" id="unite" name="unite" class="form-control"/>
            </div>

            </div>




            <div class="row">
                

            <div class="form-group col">
                <label for="qte_min">Quantité Min</label>
                <input type="text" v-model="itemForm.qte_min" id="qte_min" name="qte_min" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte_min.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.qte_min" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.qte_min" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="form-group col">
                <label for="tva">TVA (%)</label>
                <input type="text" v-model="itemForm.tva" id="tva" name="tva" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.tva.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.tva" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.tva" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            </div>


            <div class="row">
                
            <div class="form-group col">
                <label for="prix_achat">Prix Achat HT</label>
                <input type="text" v-model="itemForm.prix_achat" id="prix_achat" name="prix_achat" class="form-control"
                 :class="{ 'is-invalid': isSubmitted && $v.itemForm.prix_achat.$error }"/>
                <div v-if="isSubmitted && !$v.itemForm.prix_achat" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.prix_achat" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            <div class="form-group col">
                <label for="prix_vent">Prix Vente TTC</label>
                <input type="text" v-model="itemForm.prix_vent" id="prix_vent" name="prix_vent" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.prix_vent.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.prix_vent" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.prix_vent" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>
            </div>





            <div class="row">
                

            <div class="form-group col">
            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="is_lot_manag"
                    v-model="itemForm.is_lot_manag"
                    name="is_lot_manag"
                    value=true
                    unchecked-value=false>

            <label class="custom-control-label" for="is_lot_manag">Gestion Par Lots</label>
            </div>
            </div>
            </div>






            <div v-if="itemForm.depot_centre" class="form-group">
                        <div class="form-group">
                            <label for="centre">Centre</label>
                            <select class="form-control" id="centre" v-model="itemForm.centre_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.centre_id.$error }">
                            <option v-for="item in categories" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>
                        </div>

                            <div v-if="isSubmitted && !$v.itemForm.centre_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>


            <div class="form-group">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemForm.description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>




    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

        <complots v-bind:LotsList="Lotslist" 
        :depots="depots" 
        :article_id="stockPars.article_id"
        :article_ref="stockPars.article_ref"
        :is_mangLot="stockPars.is_mangLot"></complots>
        </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
import complots from '@/components/Stock/MangLots.vue';
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
    computed: {
        entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
      rows_pagin() {
        return this.itemslist.length
    }
    },
    mounted(){
        //this.fetchList()

        this.fetchFormCategorisDepots()
    },
	data () {
		return {
            selectedCategorie:'',
            filter: null,
            perPage: 10,
            currentPage: 1,
            categories:[],
            depots:[],
            itemslist: [],
            Lotslist:[],
            itemForm: {
                    id:'',
                    ref:'',
                    code_bar:'',
                    description:'',
                    qte_min:'',
                    is_service:'',
                    is_lot_manag:'',
                    prix_achat:'',
                    prix_vent:'',
                    tva:20,
                    unite:'U',
                    categorie_id:''},
            stockPars: {
                    article_id:'',
                    article_ref:'',
                    is_mangLot:''},
            fields: [
				{key: 'ref', label: 'Réf', sortable: true},
                {key: 'prix_achat', label: 'Prix Achat', sortable: true},
                {key: 'prix_vent', label: 'Prix Vente', sortable: true},
                {key: 'unite', label: 'Unité', sortable: true},
                {key: 'tva', label: 'TVA', sortable: true},
                {key: 'qte_min', label: 'Qté Min', sortable: true},

                {key: 'is_lot_manag', label: 'Gst Lot', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false,

		}
	},
    validations: {
            itemForm: {
                ref: {
                    required
                },
                qte_min: {
                    required,
                    decimal
                },
                is_service: {
                    required
                },
                is_lot_manag: {
                    required
                },
                prix_achat: {
                    required,
                    decimal
                },
                prix_vent: {
                    required,
                    decimal
                },
                tva: {
                    required,
                    decimal
                },
                categorie_id: {
                    required
                },
            }

    },
    methods :{

        addBarreCode_click()
        {
            this.itemForm.code_bar=Date.now()
        },

exportDox()
        {

        let cent = ""


        if(this.selectedCategorie==0)
        {
        cent="Tous"
        }
        else
        {
        let mm=this.categories.findIndex(item => item.id === this.selectedCategorie)

        cent= this.categories[mm].nom
        }



        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                            children: [
                                            new TextRun({
                                            text: "Référence",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Prix Achat",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                                                width: {
                                                    size: 2000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Prix Vente",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                                                width: {
                                                    size: 2000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Unité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        
                                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })]

        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                        before: 50,
                                        after: 50
                                    },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.prix_achat.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                      children: [
                                        new TextRun({
                                            text: item.prix_vent.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.unite,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });

        const table = new Table({

            rows:  datrw
        })

        const doc = new Document({

    sections: [
        {


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                left:500,
                                right:500
                            }
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.entet_pag,
                                color: '000000',
                                bold: true,

                                }),
                                    ],
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_1,
                                    alignment: AlignmentType.CENTER,
                                }),




                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.pied_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                top: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),

                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                            color: '000000'
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                            color: '000000'
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },




                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Liste Des Articles",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            }), 

                                new TextRun({
                                text: "     Catégorie : "+cent,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),




                        table
                        
                        ]


        }]



                });








            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "Liste_articles.docx");

            });

        },









        click_ArticlList()
        {
                    if(this.selectedCategorie)
                        {

                    this.isLoading=true;
                    this.$http.get('/articlesList/'+this.selectedCategorie)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;

                                
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                        }
                        else
                        {
                                        this.flashMessage.show({
                                        status: 'info',
                                        title: 'Sélectionez une Catégorie et Reésayez !!',
                                        })
                        }
        },
        itemslisttore(){
            this.isLoading=true;

            this.$http.post('/articles',{
                   
                    ref:this.itemForm.ref,
                    code_bar:this.itemForm.code_bar,
                    description:this.itemForm.description,
                    qte_min:this.itemForm.qte_min,
                    is_service:this.itemForm.is_service,
                    is_lot_manag:this.itemForm.is_lot_manag,
                    prix_achat:this.itemForm.prix_achat,
                    prix_vent:this.itemForm.prix_vent,
                    tva:this.itemForm.tva,
                    unite:this.itemForm.unite,
                    categorie_id:this.itemForm.categorie_id,


                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/articles/'+this.itemForm.id,{
                    ref:this.itemForm.ref,
                    code_bar:this.itemForm.code_bar,
                    description:this.itemForm.description,
                    qte_min:this.itemForm.qte_min,
                    is_service:this.itemForm.is_service,
                    is_lot_manag:this.itemForm.is_lot_manag,
                    prix_achat:this.itemForm.prix_achat,
                    prix_vent:this.itemForm.prix_vent,
                    tva:this.itemForm.tva,
                    unite:this.itemForm.unite,
                    categorie_id:this.itemForm.categorie_id,

                })
                .then(response => {

                    this.isLoading=false;
                    this.$bvModal.hide('banqModal')

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/articles/'+this.itemForm.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {

                    this.itemForm.ref='',
                    this.itemForm.code_bar='',
                    this.itemForm.description='',
                    this.itemForm.qte_min=0,
                    this.itemForm.is_service=false,
                    this.itemForm.is_lot_manag=false,
                    this.itemForm.prix_achat=0,
                   this.itemForm.prix_vent=0,
                   this.itemForm.tva=20,
                   this.itemForm.unite='U'


                   if(this.selectedCategorie==0)
                   {
                        this.itemForm.categorie_id=''
                   }
                   else
                   {
                        this.itemForm.categorie_id= this.selectedCategorie
                   }
                    



        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
      },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {
                      this.itemForm.id=item.id,
                      this.itemForm.ref=item.ref,
                    this.itemForm.code_bar=item.code_bar,
                    this.itemForm.description=item.description,
                    this.itemForm.qte_min=item.qte_min,
                    this.itemForm.is_service=item.is_service,
                    this.itemForm.is_lot_manag=item.is_lot_manag,
                    this.itemForm.prix_achat=item.prix_achat,
                   this.itemForm.prix_vent=item.prix_vent,
                   this.itemForm.tva=item.tva,
                   this.itemForm.unite=item.unite,
                    this.itemForm.categorie_id=item.categorie_id,

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


      },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
                    this.itemForm.id=item.id,
                    this.itemForm.ref=item.ref,
                    this.itemForm.code_bar=item.code_bar,
                    this.itemForm.description=item.description,
                    this.itemForm.qte_min=item.qte_min,
                    this.itemForm.is_service=item.is_service,
                    this.itemForm.is_lot_manag=item.is_lot_manag,
                    this.itemForm.prix_achat=item.prix_achat,
                    this.itemForm.prix_vent=item.prix_vent,
                    this.itemForm.categorie_id=item.categorie_id,


                                    this.banqueDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },
        stock_click(item) {

                this.$confirm("Voulez-vous gerer le stock de cet article ??")
                 .then(() => {
                    this.stockPars.article_id=item.id
                    this.stockPars.article_ref=item.ref
                    this.stockPars.is_mangLot=item.is_lot_manag

                    this.fetchLotsList(this.stockPars.article_id)



                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },

        fetchLotsList: function (art_id) {
          this.isLoading = true;

                    this.$http.get('/lotsArticle/'+art_id)
                    .then((result) => {
                                this.Lotslist = result.data.data
                                this.isLoading=false;

                                this.$root.$emit('bv::show::modal', 'lolmodal' )
                    })
        .catch(err => {
                       this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                            })
                
          console.log(err.message);
        });




        },
        fetchFormCategorisDepots: function () {
                    //chager les categories:
                    this.isLoading=true;
                    this.$http.get('/categories')
                    .then((result) => {
                                this.categories = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });




                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depots')
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmit() {






                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

            },

                                NewBon()
        {
                    this.$router.push({ 
                        name: 'menuStock'
                    });
        },

    },
    components: {
              Loading,
              complots  
    }
}
</script>

<style  scoped>
.var_color{
  color: #35b5e4;
}
</style>