<template>
     <div class="vue-tempalte pt-3">


<div class="col-10 inner-block">




<div class=" d-flex justify-content-center logcolor  mb-4">
 <h2 >
AM
   <span class="font-weight-lighter">   Business Manager</span>
</h2>
</div>


<div class="row m-0 ">

        <div class="col-xl-6 col-md-6">

        <img alt="logo" src="../../assets/login.png" class="col pl-4 pr-4 pt-0">
        </div>

        <div class="col-xl-6 col-md-6 register-right pt-5">



                <form  @submit.prevent="handleSubmit" class="mt-1">

                    <div class="form-group">

                        <input type="text" class="form-control form-control-lg rounded-pill logcolorbord" v-model="email" placeholder="Login" />
                    </div>

                    <div class="form-group">

                        <input type="password" class="form-control form-control-lg rounded-pill logcolorbord" v-model="password" placeholder="Mot De Passe" />
                    </div>
                    <div style="color:red;" v-if="error">{{ error }}</div>

                    <button type="submit" class="btn btn-info logcolorback btn-lg btn-block rounded-pill mt-4">Connexion</button>
                </form>
        </div>

</div>

<div class=" d-flex justify-content-center logcolor mt-3">
 <h6 >
AM
   <span class="font-weight-lighter">   Business Manager v90.122 - Copyright © 2013-2024 . Tous droits réservés</span>
</h6>
</div>










</div>

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {

  
  data() {
    return {
      email: "",
      password: "",
      error: "",
      isLoading: false
    };
  },
  methods:{

        handleSubmit() {
              /*  this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                } loadDroitListX  */

      this.isLoading = true;
      this.$store
        .dispatch("performLoginAction", {
          email: this.email,
          password: this.password
        })
        .then(res => {
          this.isLoading = false;


this.loadDroits(res.data.user.id)



        })
        .catch(err => {
          this.isLoading = false;
          this.error = " Entrez un login et un mot de passe valide !!";
          console.log(err.message);
        });








            },
            
        loadDroits(user_i) {


      this.isLoading = true;
      this.$store
        .dispatch("loadDroitListX", {
          user_id: user_i,
        })
        .then(res => {
          this.isLoading = false;


          console.log(res);

                    this.$router.push({ 
                        name: 'Home'
                    });
        })
        .catch(err => {
          this.isLoading = false;
          this.error = " Une erreur s'est produite lors du processus de connexion";
          console.log(err.message);
        });








            }

  },
      components: {
              Loading      
    }

}
</script>

<style scoped>

.vue-tempalte,
.vertical-center {
  width: 100%;
  height: 100%;
}


.vertical-center {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;    
}

.inner-block {

  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 20px rgba(34, 35, 58, 0.2);
  padding: 30px 30px 30px 30px;
  border-radius: 15px;
  transition: all .3s;
}

.vertical-center .form-control:focus {
  border-color: #2554FF;
  box-shadow: none;
}

.vertical-center h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

label {
  font-weight: 500;
}


.userpic {
  width: 300px;
}
.logcolor {
  color: #9774b6;
}

.logcolorback {
  background: #9774b6;
}


.logcolorbord {
  border-color: #a38db7;
}

.register-right{
    background: #ffffff;
    border-top-right-radius: 10% 50%;
    border-bottom-right-radius: 10% 50%;
}

</style>